import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from "react-redux";
import Button from "../Button/Button";
import translations from "../../translations";
import GlobalConfig from "../../shared/GlobalConfig";
import agreementActions from "../../store/actions/agreementAction";
const ALLOWED_DESKTOP_OS = [GlobalConfig.IOS, GlobalConfig.AOS];

const AbridgeConsent = (abridgeConsentProps) => {

  // parse props
  const {
    visitType,
    validateConfig: { ambntlistngConsent = null, ...validateConfigData }, 
    deviceDetails: { deviceType, deviceOs }
  } = abridgeConsentProps;

  const dispatch = useDispatch();

  // parse dependent store values
  const { lang: localizedLanguageCode } = useSelector(state => state?.localization);

  // set local state variables
  const [translation, setTranslation] = useState(translations[localizedLanguageCode]);
  const [consentVerbiageList, setConsentVerbiageList] = useState(null);

  const submitUserConsent = (ambntlistngConsentStatus) => {
    const abridgeConsentActionPayload = {
      validateConfigData,
      ambntlistngConsentStatus,
      vveConsentStatus: translation.ACCEPT
    };
    dispatch(agreementActions.submitAbridgeConsentAction(abridgeConsentActionPayload));
  };

  // action handlers
  const handleAccept = () => {
    submitUserConsent(GlobalConfig.AMBNT_CONSENT_AGREE);
  };

  const handleDecline = () => {
    submitUserConsent(GlobalConfig.AMBNT_CONSENT_DISAGREE);
  };

  // layout elements
  const AgreeButtonElement = (
    <Button modal=""
            disabled={false}
            tabIndex="0"
            refValue=""
            analyticsName=""
            onClick={handleAccept}
            class="vve-modal-agree"
            id="vve-modal-agree">
      {visitType === GlobalConfig.OP_VISIT_TYPE ? translation.ACCEPT_BTN : translation.ACCEPT_BUTTON}
    </Button>
  );

  const DeclineButtonElement = (
    <Button modal=""
            disabled={false}
            tabIndex="0"
            refValue=""
            analyticsName=""
            onClick={handleDecline}
            class="vve-modal-decline"
            id="vve-modal-decline">
      {translation.DECLINE_BUTTON}
    </Button>
  );

  const renderAmbientActionButtons = () => {
    let actionButtonList = [DeclineButtonElement, AgreeButtonElement];
    const isMobileDevice = deviceType === GlobalConfig.MOBILE;
    if(isMobileDevice || ALLOWED_DESKTOP_OS.includes(deviceOs)) {
      actionButtonList = actionButtonList.reverse();
    }
    return actionButtonList.map((buttonElement) => <>{buttonElement}</>);
  };
  
  // parse and set all depndencies based on localization
  useEffect(() => {
    document.documentElement.lang = localizedLanguageCode;
    setTranslation(translations[localizedLanguageCode]);
    const consentLanguageKey = (localizedLanguageCode === 'en') ? "english" : "spanish";
    setConsentVerbiageList(ambntlistngConsent[consentLanguageKey]);
  }, [localizedLanguageCode]);

  return (
    <>
      { consentVerbiageList &&
        (
          <>
            <ul className='Agreement-list vve-agreement-noList'>
              { consentVerbiageList.map((content, index) => <li key={`abridge-${index}`}>{content}</li>) }
            </ul>
            <div className="vve-agreement-button-div">
              {renderAmbientActionButtons()}
            </div>
          </>
        )
      }
    </>
  );
}

AbridgeConsent.propTypes = {
  visitType: PropTypes.string,
  validateConfig: PropTypes.object,
  deviceDetails: PropTypes.object
};

export default AbridgeConsent;