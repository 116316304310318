import translations from "../translations";
import Bowser from 'bowser';

export const navigateOnDecline = (language) => translations[language.toLowerCase()]["REDIRECT_URL"];

export const getQueryString = (str) => {
  return  str?.split("?")[1];
};

const userAgent = navigator.userAgent;
const browser = Bowser.getParser(userAgent);

export const getDeviceDetails = () => {
  const deviceDetails = {
    os: null,
    type: null,
  };
  deviceDetails.os = osDetector();
  deviceDetails.type = deviceType();
  return deviceDetails;
};

export const osDetector = () => {
  const osDetect = {
    iOS: 'ios',
    Android: 'aos',
    macOS: 'mac',
    'Windows Phone': 'windows',
    Windows: 'windows',
  };
  return osDetect[browser.getOSName()] || null;
};

export const deviceType = () => {
  return browser.getPlatformType() == "mobile" ? "mobile" : "desktop";
};

export const openExternalLinkTab = (url) => {
  window.location.replace(url);
};

export const zoomMeetingEvents = (zmMeeting, subscribe) => {
  //only support meeting
  zmMeeting.inMeetingServiceListener("onUserJoin", (data) => {
    console.log("onUserJoin", data);
  });

  zmMeeting.inMeetingServiceListener("onUserLeave", (data) => {
    console.log("onUserLeave", data);
  });

  zmMeeting.inMeetingServiceListener("onUserIsInWaitingRoom", (data) => {
    console.log("onUserIsInWaitingRoom", data);
  });

  zmMeeting.inMeetingServiceListener("onMeetingStatus", (data) => {
    console.log("onUserMeetingStatus", data);
    if (subscribe) {
      if (data.meetingStatus === 1) {
        subscribe("WAITING");
      }
      else if (data.meetingStatus === 2) {
        subscribe("STARTED");
      }
      else if (data.meetingStatus === 3) {
        subscribe("ENDED");
      }
    }
  });
};
